<template>
  <el-row>
    <Apply-Table
      ref="applyTable"
      v-if="applyInfo"
      :applyInfo.sync="applyInfo"
      :loading="loading"
    ></Apply-Table>
    <el-row
        type="flex"
        justify="center"
        style="margin-top: 20px"
        v-if="applyInfo"
      >
        <el-button :disabled="canDownload" @click="singleAffixDownload"
          >申请表下载</el-button
        >
      </el-row>
    <Affix :affix="applyInfo"></Affix>
    <Approve-Process :approveProcessInfo="applyInfo"></Approve-Process>
    <ApproveResult
      :isApprove="isApprove"
      @submit="approveSubmit"
    ></ApproveResult>
    <!-- <div v-else class="back">
      <el-button @click="goBack">返回</el-button>
      <el-button @click="goBack">提交</el-button>
    </div> -->
    <el-dialog
      @close="refresh"
      :title="popTitle"
      :visible.sync="popVisible"
      :destroy-on-close="true"
    >
      <div v-if="popTitle == '签章'">
        <Sign
          :popTitle="popTitle"
          @data="refresh"
          @signState="signState"
          @hide="hide"
        ></Sign>
      </div>
      <div v-if="popTitle == '遇到问题'">
        <SignBack :popTitle="popTitle" @hide="popVisible = false"></SignBack>
      </div>
    </el-dialog>
  </el-row>
</template>

<script>
import ApplyTable from "@/components/approve/ApplyTable";
import Affix from "@/components/approve/Affix";
import ApproveProcess from "@/components/approve/ApproveProcess";
import ApproveResult from "@/components/approve/ApproveResult";
import Sign from "@/components/sign/sign.vue";
import SignBack from "@/components/sign/signBack.vue";
import { getApplyAllowanceId, setApplyAllowanceFlow } from "@/service/factory";
import { gennerateHTMLtemplate } from "@/utils";
import { Loading } from "element-ui";
import { contractAddr, sealIn, contractInfo } from "@/service/contract";
export default {
  components: {
    ApplyTable,
    Affix,
    ApproveProcess,
    ApproveResult,
    Sign,
    SignBack,
  },
  computed: {
    canDownload() {
      // 设计中心
      return !(
        this.applyInfo &&
        this.applyInfo.approves.find((item) => item.code === "2501") &&
        this.applyInfo.approves.find((item) => item.code === "2501").signedAt
      );
    },
    isApprove() {
      // 草稿状态
      // 工厂提交审核完毕,设计中心待审核
      // 设计中心审核完毕,转企升规办待审核
      // 转企升规办审核完毕,家促局待审核
      // type 1:审批(编辑状态) 2: 查看信息(非编辑状态)
      const conditon1 =
        this.$route.query.type == "1" &&
        this.applyInfo &&
        this.applyInfo.currentNode === "2501" &&
        this.isDesignCenter;
      const conditon2 =
        this.$route.query.type == "1" &&
        this.applyInfo &&
        this.applyInfo.currentNode === "2502" &&
        this.$store.state.loginUser.comp.comp_type === 9902;
      const conditon3 =
        this.$route.query.type == "1" &&
        this.applyInfo &&
        this.applyInfo.currentNode === "2503" &&
        this.isFurniturBureau;
      return conditon1 || conditon2 || conditon3;
    },
    isDesignCenter() {
      return this.$store.state.loginUser.comp.comp_type === 9901;
    },
    isFurniturBureau() {
      return this.$store.state.loginUser.comp.comp_type === 9903;
    },
  },
  data() {
    return {
      descInfo: null,
      applyInfo: null,
      loading: false,
      popTitle: null,
      popVisible: false,
    };
  },
  async created() {
    const id = this.$route.params.id;
    if (id) {
      this.getApplyAllowanceDetail(id);
    }
  },
  methods: {
    singleAffixDownload() {
      const { compName, approves, id } = this.applyInfo;
      this.$confirm("下载为当前预览格式,确认下载?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          // 设计中心或者升规办不通过 a3 就不存在,只会有一份文件
          let a1 = approves.find((item) => item.code === "2501");
          let a3 = approves.find((item) => item.code === "2503");
          const fileData = [
            {
              folderName: "申请表",
              fileData: !a3
                ? [a1.contract_no]
                : [a1.contract_no, a3.contract_no],
            },
          ];
          this.exportZip(fileData, `${compName}${id}申报材料`);
        })
        .catch((e) => {
          console.error(e.message);
          this.$message.info("取消");
        });
    },
    // 单条申请记录导出申报材料
    exportZip(fileData, fileName) {
      import("@/vendor/Export2Zip")
        .then((excel) => {
          excel.export_file_to_zip1(
            fileData,
            `${fileName}${this.$moment().format("YYYYMMDDHHmmss")}`
          );
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    autoInSeal(contract_no) {
      sealIn({
        sign_positions: [
          {
            pagenum: 0,
            x: 200,
            y: 200,
          },
        ],
        contract_no,
        comp_id: this.applyInfo.compId,
      }).then((rst) => {});
    },
    refresh() {
      this.$message.success("审批完成!");
      this.$router.replace("/approve/allowance");
    },
    goBack() {
      this.$router.push("/approve/allowance");
    },
    getApplyAllowanceDetail(id) {
      this.loading = true;
      getApplyAllowanceId(id)
        .then((rst) => {
          if (rst) {
            this.applyInfo = rst;
            this.applyInfo.is_in_spec = rst.comp.is_in_spec;
            this.applyInfo.isFlow2 = !this.isDesignCenter;
            this.applyInfo.isFlow4 = !this.isFurniturBureau;
            const result1 = rst.approves.find((item) => item.code == "2501");
            // console.log("result1", result1);
            this.applyInfo.result1 = (result1 && result1.desc) || "";
            const result2 = rst.approves.find((item) => item.code == "2503");
            this.applyInfo.result2 = (result2 && result2.desc) || "";
            // console.log("result2", result2);
            // console.error("详情获取的 applyinfo", this.applyInfo);
          }

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$message.error(err.message);
        });
    },
    approveSubmit(data) {
      this.approveLoading = Loading.service({
        fullscreen: true,
        text: "提交审批中....",
      });
      let params = {
        flowId: this.$route.params.id,
        state: data.isApproved ? 1 : 2,
        cause: data.reason,
      };

      if (this.isDesignCenter) {
        if (data.item && !params.detail) {
          params.detail = {};
          params.detail.item = data.item;
        }

        if (!params.desc) {
          params.desc = this.applyInfo.result1;
        }
        params.html = gennerateHTMLtemplate(this.applyInfo, 2);
      }

      if (this.$store.state.loginUser.comp.comp_type === 9902) {
        params.html = gennerateHTMLtemplate(this.applyInfo, 3);
      }
      console.log("提交applyInfo", this.applyInfo);

      if (this.isFurniturBureau) {
        if (data.applyAmount && !params.detail) {
          params.detail = {};
          params.detail.amount = data.applyAmount;
        }

        if (!params.desc) {
          params.desc = this.applyInfo.result2;
        }
        params.html = gennerateHTMLtemplate(this.applyInfo, 4);
      }

      // console.log("detail", params);
      setApplyAllowanceFlow(params)
        .then((rst) => {
          console.log("审批返回结果", rst);
          this.approveLoading.close();
          if (this.isDesignCenter || this.isFurniturBureau) {
            this.goSign(rst);
          } else {
            this.goBack();
          }
          // if (rst.state === 1) {
          //   this.goSign(rst);
          // } else if (rst.state === 2) {
          //   this.refresh();
          // }
        })
        .catch((e) => {
          this.$message.error(e.message);
          this.approveLoading.close();
        });
    },
    goSign(row) {
      if (this.$store.state.loginUser.comp.cert_stat != 2) {
        this.$message.error("企业未认证");
        return;
      } else {
        this.$confirm("确认是否发起签章?", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          type: "warning",
        })
          .then(() => {
            this.going(row.contract_no);
          })
          .catch((e) => {
            console.error(e);
            this.goBack();
            this.$message.info("取消");
          });
      }
    },
    going(contract_no) {
      this.signLoading = Loading.service({
        fullscreen: true,
        text: "拼命加载中....",
      });
      contractAddr(contract_no)
        .then((rst) => {
          console.log("contractAddr", rst);
          if (rst && rst.url) {
            this.sign(rst.url, contract_no);
          }
        })
        .catch((e) => {
          this.$message.error(e.message);
          this.signLoading.close();
        });
    },
    sign(url, contract_no) {
      this.contract_no = contract_no;
      this.signLoading.close();
      if (url) window.open(url);
      this.signState("签章");
    },
    hide() {
      this.popVisible = false;
      this.$message.success("状态已更新");
      this.getContractInfo(this.contract_no);
    },
    getContractInfo(contract_no) {
      contractInfo(contract_no).then((rst) => {
        if ([4].includes(rst.stat)) {
          this.autoInSeal(contract_no);
        }
      });
    },
    signState(text) {
      this.popTitle = text;
      this.popVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.back {
  padding-left: 20px;
}
</style>